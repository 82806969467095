<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  // provide() {
  //   return {
  //     reload: this.reload
  //   }
  // },
  data() {
    return {
      // isReload: true
    }
  },
  methods: {
    // reload() {
    //   this.isReload = false
    //   this.$nextTick(() => {
    //     this.isReload = true
    //   })
    // }

  },
  mounted() {
    // window.onload = function () {
    //   document.addEventListener("touchstart", function (event) {
    //     if (event.touches.length > 1) {
    //       event.preventDefault()
    //     }
    //   })
    //   document.addEventListener("gesturestart",function (event){
    //     event.preventDefault()
    //   })
    // }
  },
  computed: {},
  created() {


  },

}
</script>
<script>
//禁止双指放大
document.documentElement.addEventListener('touchstart', function (event) {
  if (event.touches.length > 1) {
    event.preventDefault();
  }
}, {passive: false});
// 禁止双击放大
let lastTouchEnd = 0;
document.documentElement.addEventListener('touchend', function (event) {
  var now = Date.now();
  if (now - lastTouchEnd <= 300) {
    event.preventDefault();
  }
  lastTouchEnd = now;
}, {passive: false});

</script>

<style>
/*1rem=10px*/
@import "./assets/css/comm_wap.css";

/* 标题字体 */
@font-face {
  font-family: "MGQ8102";
  src: url("./assets/fonts/MGQ8102.ttf");
}

/* 正文字体 */
@font-face {
  font-family: "MHR8102";
  src: url("./assets/fonts/MHR8102.ttf");
}

.titlefont {
  font-family: "MGQ8102" !important;
}

.contentfont {
  font-family: "MHR8102" !important;
}

#app {
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  background: #ededed;
}

/* 元素竖排 */
.aqt_su {
  writing-mode: vertical-lr;
}

/* 元素取消竖排 */
.aqt_no_su {
  writing-mode: horizontal-tb;
}

/* 蒙语字竖起来的属性 */
.aqt_lang_mn {
  writing-mode: vertical-lr;
  text-orientation: sideways-right;
  text-orientation: sideways !important;
  -o-text-orientation: sideways !important;
  -ms-text-orientation: sideways !important;
  -moz-text-orientation: sideways !important;
  -webkit-text-orientation: sideways !important;
  -webkit-text-orientation: sideways-right !important;
}

/* 上下左右剧中 */
.aqt_ce_ve {
  display: flex;
  width: 100%;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  display: -ms-flexbox;
  justify-content: center;
}
img{
  pointer-events:none;

  user-select:none;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
}

</style>
