import Vue from 'vue'
import Vuex from 'vuex'
import Http from '@/http/index'

Vue.use(Vuex)

// 	var nt = plus.networkinfo.getCurrentType()
// 	// plus.networkinfo.CONNECTION_UNKNOW = 0//网络连接状态未知
// 	// plus.networkinfo.CONNECTION_NONE = 1//未连接网络
// 	// plus.networkinfo.CONNECTION_ETHERNET = 2//有线网络
// 	// plus.networkinfo.CONNECTION_WIFI = 3//无线WIFI网络
// 	// plus.networkinfo.CONNECTION_CELL2G = 4//蜂窝移动2G网络
// 	// plus.networkinfo.CONNECTION_CELL3G = 5//蜂窝移动3G网络
// 	// plus.networkinfo.CONNECTION_CELL4G = 6//蜂窝移动4G网络
// 	// plus.networkinfo.CONNECTION_CELL5G = 7//蜂窝移动5G网络
function getwifi(){
    // console.log('高艳Wi-Fi')
    // console.log(plus)
    // console.log(plus.android)
    // console.log(plus.ios)
    var Context = plus.android.importClass("android.content.Context");
    var WifiManager = plus.android.importClass("android.net.wifi.WifiManager");
    var Main = plus.android.runtimeMainActivity();
    var WifiManager = Main.getSystemService(Context.WIFI_SERVICE);
    var WifiInfo = plus.android.importClass("android.net.wifi.WifiInfo");
    var WifiInfo = WifiManager.getConnectionInfo();
    // console.log(WifiInfo)
    // console.log('wifi名称',WifiInfo.getSSID())//获取wifi名称
    // console.log('接入网络的mac地址点的地址',WifiInfo.getBSSID())// 获取被连接网络的mac地址
    // console.log('本机的MAC地址  ',WifiInfo.getMacAddress())//获取本机的MAC地址
    // console.log('IP 地址',WifiInfo.getIpAddress())//获取IP 地址
    // console.log('4444444',plus.android.net.WifiManager())//等待测试
    // console.log('5555555',plus.android.net.WifiInfo())//等待测试
    return WifiInfo.getBSSID();

}
function getUUID() {
    return new Promise((re, je) => {
        let gg = {
            uuid:null,
            network:null,
            latlon:null,
            wificode:null,
        }
        gg.network = plus.networkinfo.getCurrentType()
        if(Vue.prototype.iosversion.name != 'iOS'){
            gg.wificode = getwifi();
        }
        plus.device.getInfo({
            success: function(e) {
                gg.uuid = e.uuid
                plus.geolocation.getCurrentPosition(
                    function(p) {
                        gg.latlon = p.coords.latitude+','+p.coords.longitude
                        re(gg)
                    },
                    function(i) {
                        re(gg)
                    },
                    {
                        provider: 'amap',
                        enableHighAccuracy:true,
                        coordsType:'gcj02',
                        maximumAge:1000
                    }
                )
            },
            fail: function(e) {
                plus.geolocation.getCurrentPosition(
                    function(p) {
                        gg.latlon = p.coords.latitude+','+p.coords.longitude
                        re(gg)
                    },
                    function(u) {
                        re(gg)
                    },
                    {
                        provider: 'amap',
                        enableHighAccuracy:true,
                        coordsType:'gcj02',
                        maximumAge:1000
                    }
                )
                console.log('getDeviceInfo failed: ' + JSON.stringify(e))
            }
        })

    })
}
function gethhh(){
    return new Promise((resolve, reject) => {
        if(JSON.parse(localStorage.getItem('theme_color')) && JSON.parse(localStorage.getItem('theme_color')).library_id){
            let jj = {
                library_id:JSON.parse(localStorage.getItem('theme_color')).library_id
            }
            clearInterval(Vue.prototype.$getBlackWhiteTimer);
            Http.get(`api/web/v1/book/blackwhitelist`, {params:jj})
                .then(val =>{
                    resolve(val)
                }).catch(err =>{
                reject(err)
            })
        }
    })
}
export default new Vuex.Store({
    state: {
        search: {
            title: '',
            name: '',//书名
            category_id: '',//书籍分类id
            category_name: '',//书籍分类名称
            culture_id: '',//文化分类id
            culture_name: '',//文化分类名称
            press_id: '',//出版社id
            press_name: '',//出版社名称
            isbn: '',//书号
            author: '',//作者名称
            start_time: '',//出版开始时间
            end_time: '',//出版结束时间
        },
        searchzh: {
            searchtype: 1, //汉语列表搜索表示，不传默认是蒙语列表
            status: "1", //1书名、2isbn号、3作者、4出版社
            keyword: "", //关键词
            page: 0, //分页
            type: 2, //书籍类型和蒙语列表状态一样
            library_id:null, //馆藏id  48内蒙古财经大学图书馆
        },
        content_id: null,
        books: [],
        paperInfo: {},
        refresh: false,
        library_id: localStorage.getItem('theme_color') ? JSON.parse(localStorage.getItem('theme_color')).library_id : null,
        isclick: false,
        uuid:null,//设备唯一码
        network:null,//网络
        wificode:null,//wificode
        latlon:null,//经纬度
        blackwhitelist:[],//黑白名单
        annexboolean:false,//控制附件是否显示
        lang:localStorage.getItem('lang') ? localStorage.getItem('lang') : null,//语言
        indexdata:localStorage.getItem('indexdata') ? JSON.parse(localStorage.getItem('indexdata')) : null,//语言
    },
    mutations: {
        increment(state, theme_color) {
            // 变更状态
            state.library_id = theme_color.library_id
        },
        // 赋值设备唯一码
        SET_CROWDCLASS: (state, uuid) => {
            state.uuid = uuid
        },
        // 赋值网络
        SET_NETWORK: (state, network) => {
            state.network = network
        },
        // 赋值wificode
        SET_WIFICODE: (state, wificode) => {
            state.wificode = wificode
        },
        // 赋值经纬度
        SET_LATLON: (state, latlon) => {
            state.latlon = latlon
        },
        // 黑白名单
        SET_BLACKWHITE: (state, blackwhitelist) => {
            state.blackwhitelist = blackwhitelist
        },
        // 控制附件是否显示
        SET_ANNEXBOOLEAN: (state, annexboolean) => {
            state.annexboolean = annexboolean
        },
        // 设置语言
        SET_LANG: (state, lang) => {
            state.lang = lang
        },
        // 首页数据
        SET_INDEXDATA: (state, indexdata) => {
            state.indexdata = indexdata
        }
    },
    getters: {
        getSearch: state => state.search,
        uuid: state => state.uuid,
        network: state => state.network,
        wificode: state => state.wificode,
        latlon: state => state.latlon,
        blackwhitelist: state => state.blackwhitelist,
        annexboolean: state => state.annexboolean,
        lang: state => state.lang,
        indexdata: state => state.indexdata,
    },
    actions: {
        /*****************************************************************
         *
         * 获取设备唯一码
         *
         *****************************************************************/
        getSebeihao({ commit }){
            let status = localStorage.getItem('shenghe_Status')
            //App Store 审核中
            if(status == 1 || !status){
                return
            }
            if (window.plus) {
                getUUID().then(val => {
                    console.log('设备')
                    console.log(val)
                    commit('SET_CROWDCLASS', val.uuid)
                    commit('SET_NETWORK', val.network)
                    commit('SET_LATLON', val.latlon)
                    commit('SET_WIFICODE', val.wificode)
                    Vue.prototype.getAnnexBoolean()
                }).catch(err => {
                    console.log(err)
                })
            } else {
                document.addEventListener(
                    'plusready',
                    function() {
                        getUUID().then(val => {
                            commit('SET_CROWDCLASS', val.uuid)
                            commit('SET_NETWORK', val.network)
                            commit('SET_LATLON', val.latlon)
                            commit('SET_WIFICODE', val.wificode)
                            Vue.prototype.getAnnexBoolean()
                        }).catch(err => {
                            console.log(err)
                        })
                    },false)
            }
        },
        /************************************************************
         *
         * 定时获取黑白名单
         *
         ************************************************************/
        getBlackWhite({ dispatch,commit }){
            gethhh().then(val => {
                Vue.prototype.$getBlackWhiteTimer = setInterval(function(){
                    dispatch('getBlackWhite').then(() => {})
                }, 360000);
                commit('SET_BLACKWHITE',val.data)
                //判断状态
                Vue.prototype.getAnnexBoolean()
            }).catch(err => {
                console.log(err)
            })
        },
        /************************************************************
         *
         * 直接获取黑白名单
         *
         ************************************************************/
        getsBlackWhite({ commit }){
            gethhh().then(val => {
                commit('SET_BLACKWHITE',val.data)
                //判断状态
                Vue.prototype.getAnnexBoolean()
            }).catch(err => {
                console.log(err)
            })
        },
        // /************************************************************
        // *
        // * 获取判断后的值
        // *
        // ************************************************************/
        getAnnexBooleanStore({ commit },annexboolean){
            commit('SET_ANNEXBOOLEAN',annexboolean)
        },
        // /************************************************************
        // *
        // * 语言赋值
        // *
        // ************************************************************/
        getLangStore({ commit },lang){
            commit('SET_LANG',lang)
        },
        // /************************************************************
        // *
        // * 获取馆藏信息
        // *
        // ************************************************************/
        getLibraryInfo({ commit },number){
            Http.post('api/web/v1/library/search/' + number).then(val => {
                let a = {
                    library_num: number,
                    protocol: val.data.protocol,
                    library_id: val.data.library_id,
                    theme_color: val.data.theme_color,
                    personal_pic: val.data.personal_pic,
                    sidebar_pic: val.data.sidebar_pic,
                    details_pic: val.data.details_pic,
                    logo_icon: val.data.logo_icon,
                    lat: val.data.lat,
                    lon: val.data.lon,
                    range: val.data.range
                }
                localStorage.setItem('theme_color', JSON.stringify(a))
                commit('increment',a)
                Vue.prototype.library_id = JSON.parse(localStorage.getItem('theme_color')).library_id//馆藏id  48内蒙古财经大学图书馆 all全部
                document.body.style.setProperty('--themeColor', a.theme_color);
                function plusReady() {
                    // 在这里调用plus api
                    plus.navigator.setStatusBarBackground(a.theme_color);
                }
                if (window.plus) {
                    plusReady();
                } else {
                    document.addEventListener('plusready', plusReady, false);
                }
            }).catch(err => {
            })
        },

        // /************************************************************
        // *
        // * 获取首页信息
        // *
        // ************************************************************/
        getIndex({ commit },library_id){
            Http.get('api/web/v1/appindex/index',{params:{library_id:library_id}}).then(val => {
                commit('SET_INDEXDATA',val.data)
                localStorage.setItem('indexdata', JSON.stringify(val.data))
            }).catch(err => {
            })
        },
    }
})
