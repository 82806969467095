import Vue from 'vue'
import './cube-ui'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import Http from './http/index'
import store from './store'
import VueI18n from 'vue-i18n'

Vue.prototype.$http = Http
Vue.prototype.axios = axios

import plug from './plugin'

Vue.use(plug)


Vue.config.productionTip = false

import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: require('./assets/img/default.jpg'),
    loading: require('./assets/img/loading.gif'),
    lazyComponent: true
})

Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: 'mn',    // 语言标识
    //this.$i18n.locale // 通过切换locale的值来实现语言切换
    messages: {
        'zh': require('./assets/lang/zh'),   // 中文语言包
        'mn': require('./assets/lang/mn'),    // 蒙文语言包
        'en': require('./assets/lang/en')    // 英文语言包
    }
})


// 设置公共的颜色样式
function changeThemeColor(color) {
    document.body.style.setProperty('--themeColor', color);
    console.log('color', color)
}

//设置手机端系统栏颜色，
function changeSys(color) {
    plus.navigator.setStatusBarBackground(color);
}

function getVUE_APP_BASE_NUMBER(number_id,next) {
    Http.get('api/web/v1/h5/search?library_id=' + number_id).then(val => {
        let a = {
            theme_color: val.data.theme_color,
            details_pic: val.data.details_pic,
            sidebar_pic: val.data.sidebar_pic,
            name:val.data.name_lang,
            library_id: number_id
        }
        localStorage.setItem('theme_color', JSON.stringify(a))
        store.commit('increment', a)
        Vue.prototype.library_id = number_id//馆藏id  48内蒙古财经大学图书馆 all全部
        changeThemeColor(JSON.parse(localStorage.getItem('theme_color')).theme_color)

        // 设置系统状态栏背景

        function plusReady() {
            // 在这里调用plus api
            changeSys(JSON.parse(localStorage.getItem('theme_color')).theme_color)
        }

        if (window.plus) {
            plusReady();
        } else {
            document.addEventListener('plusready', plusReady(), false);
        }
        tstore.dispatch('getBlackWhite').then(() => {
        })
        store.dispatch('getIndex', number_id).then(() => {
        })


    }).catch(err => {
        if (err.code == 400) {
            return
        }
    })
}


router.beforeEach((to, from, next) => {
    if(to.path=='/index_infor'||to.path=='/index_infor_mn'){
        const gcm = window.location.search.split('&')[1].split('=')
        console.log('gcm',gcm[1])
        localStorage.setItem('gcm', JSON.stringify(gcm[1]))
        const number_id = JSON.parse(localStorage.getItem('gcm'))
        getVUE_APP_BASE_NUMBER(number_id,next)
    }else if(to.path=='/bookinfor_dw'||to.path=='/bookinfor_dw_mn'){
        const number_id = JSON.parse(localStorage.getItem('gcm'))
        getVUE_APP_BASE_NUMBER(number_id,next)
    }
    else{
        const gcm = window.location.search.slice(8)
        localStorage.setItem('gcm', JSON.stringify(gcm))
        const number_id = JSON.parse(localStorage.getItem('gcm'))
        getVUE_APP_BASE_NUMBER(number_id,next)
    }

    next()

})

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
