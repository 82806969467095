import Vue from 'vue'
const plug = new Object();
import Es6Promise from 'es6-promise'

require('es6-promise').polyfill()
Es6Promise.polyfill()
require('es6-promise/auto')

plug.install = (Vue, options)=> {
    Vue.mixin({
        components: {
            // 全局组件
            LayList: re => require(['@/components/LayList'], re),
            LayConfirm: re => require(['@/components/LayConfirm'], re),
            LayActionSheet: re => require(['@/components/LayActionSheet'], re),//是否两项选择
            LayFileSelectZh: re => require(['@/components/LayFileSelectZh'], re),//多文件选择列表(中文横排)
            LayFileSelect: re => require(['@/components/LayFileSelect'], re),//多文件选择列表
        },
        computed: {
            screenWidth: {
                get: function () {
                    let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
                    return w
                },
                set: function (newValue) {
                    return newValue
                }
            },
            screeHeight: {
                get: function () {
                    let h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
                    return h
                },
                set: function (newValue) {
                    return newValue
                }
            },
        },
        mounted() {
        }

    })

    /*****************************************************************
     *
     * 全局加载动画
     * type  true显示  false隐藏
     *****************************************************************/
    Vue.prototype.showToastMask = function (type) {
        const toast = this.$createToast({
            txt: 'Loading...',
            mask: true,
            time: 10000,
            zIndex: 99999999,
        })
        if (type) {
            toast.show()
        } else {
            toast.hide()
        }
    }
    /*****************************************************************
     *
     * 全局提示弹窗
     *
     *****************************************************************/
    Vue.prototype.tips = function (time, text, type) {
        const toast = this.$createToast({
            time: time || 100000,
            txt: text || '我来了',
            type: type || 'correct'
        })
        toast.show()
    }
    /*****************************************************************
     *
     * 搜索变量
     *
     *****************************************************************/
    Vue.prototype.searchInformation = {
        title: '',
        name: '',//书名
        category_id: '',//书籍分类id
        category_name: '',//书籍分类名称
        culture_id: '',//文化分类id
        culture_name: '',//文化分类名称
        press_id: '',//出版社id
        press_name: '',//出版社名称
        isbn: '',//书号
        author: '',//作者名称
        start_time: '',//出版开始时间
        end_time: '',//出版结束时间
    }

    // 注册转码过滤器 U2M
    Vue.filter('U2M', function (value) {
        return Unicode2M(value);
    })
    // 注册转码过滤器 M2U
    Vue.filter('M2U', function (value) {
        return M2Unicode(value);
    })
    Vue.prototype.U2M = function (value) {
        //changeData是函数名
        let filetered = Unicode2M(value);
        return filetered;
    }
    Vue.prototype.M2U = function (value) {
        //changeData是函数名
        let filetered = M2Unicode(value);
        return filetered;
    }
    Vue.filter('htmlFilter', function (value) {
        let jj = '';
        if(value){
            jj = value
                .replace(value ? /&(?!#?\w+;)/g : /&/g, '&amp;')
                .replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">")
                .replace(/&quot;/g, "\"")
                .replace(/&#39;/g, "\'");
        }

        return jj;
    })
    Vue.prototype.htmlFilter = function (value) {
        let jj = '';
        if(value){
            jj = value
                .replace(value ? /&(?!#?\w+;)/g : /&/g, '&amp;')
                .replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">")
                .replace(/&quot;/g, "\"")
                .replace(/&#39;/g, "\'");
        }
        return jj;
    }
    Vue.prototype.nodata = function (val) {
        if (!val) {
            return "暂无数据";
        } else {
            return val;
        }
    }
    Vue.filter('nodata', function (val) {
        if (!val) {
            return "暂无数据";
        } else {
            return val;
        }
    })
    Vue.prototype.HtmlIMGMN = function (value) {
        let ggg = value
        if(value){
            const regex = new RegExp('<img', 'gi')
            ggg = value.replace(regex, `<img width="auto" height="auto" style="max-height: 100%; max-width: 40rem"`);
        }
        return ggg
    }
    /*****************************************************************
     *
     *
     *
     *****************************************************************/

    Vue.prototype.mmm = 0;
    /*****************************************************************
     *
     * 用于加载下一页的判断
     *
     *****************************************************************/
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        //Ios
        Vue.prototype.loadDistance = -20
    } else if (/(Android)/i.test(navigator.userAgent)) {
        //Android终端
        Vue.prototype.loadDistance =10

    }
    Vue.prototype.iosversion = {
        version: navigator.plus ? navigator.plus.os.version : 0,
        name: navigator.plus ? navigator.plus.os.name : 0
    }
    Vue.prototype.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    Vue.prototype.windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

    Vue.prototype.iosversion = {
        version: navigator.plus ? navigator.plus.os.version : 0,
        name: navigator.plus ? navigator.plus.os.name : 0
    }
    /*****************************************************************
     *
     * 内容只显示文字
     *
     *****************************************************************/
    Vue.prototype.delHtmlTags = function (str) {
        if (str != null) {
            return str.replace(/<[^>]+>/g, "").trim();
        }
    }
    Vue.prototype.divhtml = function (value) {
        if (value) {
            return value.replace(/<[^>]+>/g, "").trim();
        }
    }

}
/*****************************************************************
 *
 * 全局加载动画
 * type  true显示  false隐藏
 *****************************************************************/
Vue.prototype.showToastMask = function (type) {
    const toast = this.$createToast({
        txt: 'Loading...',
        mask: true,
        time: 30000,
        zIndex: 99999999,
    })
    if (type) {
        toast.show()
    } else {
        toast.hide()
    }
}
/*****************************************************************
 *
 * 全局提示弹窗
 *
 *****************************************************************/
Vue.prototype.tips = function (time, text, type) {
    const toast = this.$createToast({
        time: time || 1000,
        txt: text || '我来了',
        type: type || 'correct'
    })
    toast.show()
}

// 注册转码过滤器 U2M
Vue.filter('U2M', function (value) {
    return Unicode2M(value);
})
// 注册转码过滤器 M2U
Vue.filter('M2U', function (value) {
    return M2Unicode(value);
})
Vue.prototype.U2M = function (value) {
    //changeData是函数名
    let filetered = Unicode2M(value);
    return filetered;
}
Vue.prototype.M2U = function (value) {
    //changeData是函数名
    let filetered = M2Unicode(value);
    return filetered;
}

//
// Vue.prototype.mmm = 0;
/*****************************************************************
 *
 * 用于加载下一页的判断
 *
 *****************************************************************/
if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    //Ios
    Vue.prototype.loadDistance = -20
} else if (/(Android)/i.test(navigator.userAgent)) {
    //Android终端
    Vue.prototype.loadDistance = 10

}

Vue.prototype.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
Vue.prototype.windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

Vue.prototype.iosversion = {
    version: navigator.plus ? navigator.plus.os.version : 0,
    name: navigator.plus ? navigator.plus.os.name : 0
}
/*****************************************************************
 *
 * 内容只显示文字
 *
 *****************************************************************/
Vue.prototype.delHtmlTags = function (str) {
    if (str != null) {
        return str.replace(/<[^>]+>/g, "").trim();
    }
}
Vue.prototype.divhtml = function (value) {
    if (value) {
        return value.replace(/<[^>]+>/g, "").trim();
    }


}
/*******************************************************
 *
 * 不同馆藏汉语端底部显示
 *
 *********************************************************/
Vue.prototype.$newsdata =[
    {
        library_id:'',
        content_zh:{}
    },
    {
        library_id:'UDVbagFg',
        content_zh:{
            name:'镶黄旗图书馆',
            telephone:'0479-6222020',
        }
    },
    {
        library_id:'VzENNVE2',
        content_zh: {
            name:'东乌旗图书馆',
            telephone:'',
        }
    },
    {
        library_id:'VTAOPgZn',
        content_zh: {
            name:'西乌旗图书馆',
            telephone:'0479-3522336',
        }
    },

]

export default plug
