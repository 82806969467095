
import axios from 'axios'
import qs from 'qs'
import Vue from 'vue'
const instanct = axios.create({
	baseURL: 'https://culture.aqitai.com/',
	timeout: 90000,
	headers: {
		"Content-Type": "application/x-www-form-urlencoded",
},
transformRequest: [data => qs.stringify(data)]
})

instanct.interceptors.request.use((req) => {
	if(req.method=='get'){
		if(req.params){
			req.params.apiterminal = 'Aqt_20230517ApiWeb'
		}else {
			let keyVal ={
				apiterminal:'Aqt_20230517ApiWeb'
			}
			Vue.prototype.$set(req,'params',keyVal)
		}

	}else if(req.method=='post'){
		console.log('req',req)
		if(req.data){
			req.data.apiterminal = 'Aqt_20230517ApiWeb'
		}else {
			let keyVal ={
				apiterminal:'Aqt_20230517ApiWeb'
			}
			Vue.prototype.$set(req,'data',keyVal)
		}
	}

	return req
})

instanct.interceptors.response.use((res) => {
	let mainData = Object.assign({}, res.data)
	if (res.config.method == 'get') {
		let resdata = Object.assign({}, mainData.data)
		if (resdata.current_page) {
			delete resdata.data
		}
		if (mainData.code === 200) {
			return {
				data: resdata.current_page ? mainData.data.data : mainData.data,
				info: {
					'code': mainData.code,
					'message': mainData.message
				},
				pages: resdata.current_page ? resdata : null
			}
		} else {
			handleErr(mainData)
			return Promise.reject({
				'code': mainData.code,
				'message': mainData.message
			})
		}
	} else {
		if (mainData.code === 200||mainData.code === 201) {
			return mainData
		} else {
			handleErr(mainData)
			return Promise.reject({
				'code': mainData.code,
				'message': mainData.message
			})
		}
	}
}, response => {
	// console.log(696)
	// Vue.prototype.mmm++
	// console.log('m1',Vue.prototype.mmm)
	// if(Vue.prototype.mmm == 1){
	// 	console.log(99)
	// 	function plusAlert() {
	// 		plus.nativeUI.alert("网络不佳", function(){
	// 			console.log("User pressed!");
	// 		}, "提示", "OK");
	// 	}
	//
	// 	if (window.plus) {
	// 		console.log(5656)
	// 		plusAlert();
	// 	} else {
	// 		document.addEventListener('plusready', plusAlert, false);
	// 	}
	// }


	return Promise.reject(response)
}, res => {
	return Promise.reject(res)
})



function handleErr(response) {
	if (response && (response.code === 200 || response.code === 201)) {
		return response
	}
}

export default instanct
