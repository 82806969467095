export const m = {

    //书籍详情页
    xiangi: 'ᠲᠠᠨᠢᠯᠴᠠᠭᠤᠯᠭ᠎ᠠ ',// 详情页
    mingcheng: 'ᠨᠡᠷ᠎ᠡ ',//名称
    isbnhao:'ISBN ᠨᠣᠮᠧᠷ ',//isbn号
    atour:'ᠬᠡᠪᠯᠡᠯ ᠦᠨ ᠬᠣᠷᠢᠶ᠎ᠠ',//作者
    cbs:'ᠵᠤᠬᠢᠶᠠᠭᠴᠢ',//出版社
    cbtime:'ᠬᠡᠪᠯᠡᠭᠰᠡᠨ ᠴᠠᠭ',//出版时间
    page:'ᠨᠢᠭᠤᠷ ᠦᠨ ᠲᠣᠭ᠎ᠠ ',//页数
    totlezs:'ᠦᠰᠦᠭ ᠤᠨ ᠲᠣᠭ᠎ᠠ ',//总字数
    jiage:'ᠦᠨ᠎ᠡ ',//价格
    jianjie:'ᠳᠦᠭᠦᠮ ᠲᠠᠨᠢᠯᠴᠠᠭᠤᠯᠭ᠎ᠠ',//简介

    //书籍馆藏信息
    libraryname:'ᠬᠠᠳᠠᠭᠠᠯᠠᠮᠵᠢ',//图书馆
    ylroom:'ᠤᠩᠰᠢᠯᠭ᠎ᠠ ᠶᠢᠨ ᠲᠠᠰᠤᠭ',//阅览室
    suobookh:'ᠡᠳ᠋ ᠬᠦᠷᠦᠩᠭᠡ ᠵᠢᠨ  ᠳ᠋ᠤᠭᠠᠷ ',//索书号
    txm:'ᠵᠢᠷᠤᠭᠠᠰᠤᠨ ᠲᠡᠮᠳᠡᠭ',//条形码
    macinfor:'marc ᠲᠡᠮᠳᠡᠭᠯᠡᠯ   ᠦᠨ  ᠨᠣᠮᠧᠷ',//mac信息
}
