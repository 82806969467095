import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)
// 获取用户自己输入的馆藏码

export default new Router({
    routes: [
        {
            path:`/`,
            component: () => import('../components/LayContentAll.vue'),
            redirect:`/index`,
            children: [
                {
                    path: `/index`,
                    name: 'lang',
                    meta: {
                        title: 'lang',
                        mark: '语言选择',
                        type: 0,//用于记录用户行为  1书籍 2杂志
                    },
                    component: () => import('../views/lang.vue'),
                },
                {
                    path: `/index_mn`,
                    name: 'index_mn',
                    meta: {
                        title: 'Index',
                        mark: '首页',
                        type: 0,//用于记录用户行为  1书籍 2杂志
                    },
                    component: () => import('../views/MN/index_mn.vue'),
                },
                {
                    path: `/BookInfo`,
                    name: 'bookdeail',
                    meta: {
                        title: '',
                        mark: '书籍详情页',
                        type: 1,//用于记录用户行为  1书籍 2杂志
                    },
                    component: () => import('../views/MN/BookInfo.vue'),
                },

            ]
        },
        {
            path: `/`,
            component: () => import('../components/LayContentAllZh.vue'),
            redirect: `/`,
            children: [
                {
                    path: `/index_cn`,
                    name: 'index_cn',
                    meta: {
                        title: '首页',
                        mark: '汉语首页',
                        type: 0,//用于记录用户行为  1书籍 2杂志
                    },
                    component: () => import('../views/CN/index_cn.vue'),
                },
                {
                    path: `/zhBookInfo`,
                    name: 'zhBookInfo',
                    meta: {
                        title: '',
                        mark: '汉语书籍详情页',
                        type: 1,//用于记录用户行为  1书籍 2杂志
                    },
                    component: () => import('../views/CN/zhBookInfo.vue'),
                },

            ]
        },
        {
            path:`/`,
            component: () => import('../components/LayContentAll.vue'),
            redirect:`/index_dw`,
            children: [
                {
                    path: `/index_dw`,
                    name: 'lang',
                    meta: {
                        title: '',
                        mark: '地方文献',
                        type: 0,//用于记录用户行为  1书籍 2杂志
                    },
                    component: () => import('../views/Other/index_dw.vue'),
                },
                {
                    path: `/bookinfor_dw`,
                    name: 'lang',
                    meta: {
                        title: '',
                        mark: '',
                        type: 0,//用于记录用户行为  1书籍 2杂志
                    },
                    component: () => import('../views/Other/bookinfor_dw.vue'),
                },
                {
                    path: `/bookinfor_dw_mn`,
                    name: 'lang',
                    meta: {
                        title: '',
                        mark: '',
                        type: 0,//用于记录用户行为  1书籍 2杂志
                    },
                    component: () => import('../views/Other/bookinfor_dw_mn.vue'),
                }

            ]
        },
        {
            path:`/`,
            component: () => import('../components/LayContentAllZh.vue'),
            redirect:`/index_infor`,
            children: [
                {
                    path: `/index_infor`,
                    name: 'lang',
                    meta: {
                        title: '',
                        mark: '',
                        type: 0,//用于记录用户行为  1书籍 2杂志
                    },
                    component: () => import('../views/inforh5/InforIndex.vue'),
                },
                {
                    path: `/index_infor_mn`,
                    name: 'lang',
                    meta: {
                        title: '',
                        mark: '',
                        type: 0,//用于记录用户行为  1书籍 2杂志
                    },
                    component: () => import('../views/inforh5/InforIndex_mn.vue'),
                },
                {
                    path: '/zhPdfView',
                    name: 'zhPdfView',
                    meta: {
                        title: '',
                        mark: '查看pdf',
                        type: 0,//用于记录用户行为  1书籍 2杂志
                    },
                    component: () => import('../views/News/PdfView.vue'),
                },
                {
                    path: '/h5Iframe',
                    name: 'h5Iframe',
                    meta: {
                        title: '',
                        mark: 'h5页面查看',
                        type: 0,//用于记录用户行为  1书籍 2杂志
                    },
                    component: () => import('../views/News/h5Iframe.vue'),
                },

            ]
        }
    ],
    mode: 'history',
})
